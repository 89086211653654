/**
 * Format to human date
 * @param  {Date|String} date
 * @return {string}
 */
function formatHumanDate(date) {
    if (date == null)
        return 'Не указана';
    if (typeof date === 'string') {
        date = new Date(date);
    }
    else if (typeof date === 'number') {
        date = new Date(date * 1000);
    }
    
    // let minutes = date.getMinutes();
    // let hours = date.getHours();
    // let month = date.getMonth() + 1;
    // let dateTemp = date.getDate();
    // if (minutes < 10) minutes = `0${ minutes }`;
    // if (hours < 10) hours = `0${ hours }`;
    // if (dateTemp < 10) dateTemp = `0${ dateTemp }`;
    // if (month < 10) month = `0${ month }`;
    //
    // return `${ dateTemp }.${ month }.${ date.getFullYear() } ${ hours }:${ minutes }`;
    
    return getDateMMDDYYYYHHSS(date);
}

/**
 * Format to human date without time
 * @param  {Date|String} date
 * @return {string}
 */
function formatHumanDateWithoutTime(date) {
    if (date == null)
        return 'Не указана';
    if (typeof date === 'string') {
        date = new Date(date);
    }
    else if (typeof date === 'number') {
        date = new Date(date * 1000);
    }
    
    let month = date.getMonth() + 1;
    let dateTemp = date.getDate();
    if (dateTemp < 10) dateTemp = `0${ dateTemp }`;
    if (month < 10) month = `0${ month }`;
    
    return `${ dateTemp }.${ month }.${ date.getFullYear() }`;
}

/**
 * @param  {string} date
 * @return {number | string}
 */
function dateLocalToTimestamp(date) {
    let tempDate = date.split(' ');
    tempDate[0] = tempDate[0].split('.').reverse().join('-');
    // if (!tempDate[1]) tempDate[1] = '00:00:00';
    
    tempDate = tempDate.join(' ');
    
    return +new Date(tempDate) / 1000;
    // return +new Date(tempDate);
}

/**
 * @param  {string} date
 * @return {string}
 */
function dateLocalToDateServer(date) {
    let tempDate = date.split(' ');
    tempDate[0] = tempDate[0].split('.').reverse().join('-');
    
    return tempDate.join(' ');
}

/**
 * @param  {string | null} [date = null]
 * @return {string | null}
 */
function dateLocalToISO(date = null) {
    if (!date || date === '__.__.____') {
        date = getDateMMDDYYYYHHSS();
    }
    
    let tempDateTimeArray = date.split(' ');
    tempDateTimeArray[0] = tempDateTimeArray[0].split('.').reverse().join('/');
    date = tempDateTimeArray.join(' ');
    
    return new Date(date).toISOString();
}

/**
 * @param  {Number} value
 * @param  {boolean} isDays
 * @return {Number}
 */
function daysOrHoursToMilliseconds(value, isDays) {
    if (isDays)
        value *= 24;
    
    return value * 3600 * 1000;
}

/**
 *
 * @param {Date|String} [date = 'now]
 * @returns {string}
 */
function getDateMMDDYYYYHHSS(date = 'now') {
    date = (date === 'now') ? new Date() : date;
    
    let minutes = date.getMinutes();
    let hours = date.getHours();
    let month = date.getMonth() + 1;
    let dateTemp = date.getDate();
    if (minutes < 10) minutes = `0${ minutes }`;
    if (hours < 10) hours = `0${ hours }`;
    if (dateTemp < 10) dateTemp = `0${ dateTemp }`;
    if (month < 10) month = `0${ month }`;
    
    return `${ dateTemp }.${ month }.${ date.getFullYear() } ${ hours }:${ minutes }`;
}

const MONTHS_WORDS = {
    '01': 'января',
    '02': 'февраля',
    '03': 'марта',
    '04': 'апреля',
    '05': 'мая',
    '06': 'июня',
    '07': 'июля',
    '08': 'августа',
    '09': 'сентября',
    '10': 'октября',
    '11': 'ноября',
    '12': 'декабря',
};

/**
 * Получить правильное склонение слова "час"
 * @param {Number | string} hours
 */
function getHoursText(hours) {
    if (typeof hours === 'string') hours = +hours;
    
    return ([ 1, 21 ].includes(hours)) ? 'часа' : 'часов';
}

/**
 * Получить правильное склонение слова "минут"
 * @param {Number | string} minutes
 */
function getMinutesText(minutes) {
    if (typeof minutes === 'string') minutes = +minutes;
    const minutesTemp = minutes % 10;
    
    return (minutesTemp === 1 && minutes !== 11) ? 'минуты' : 'минут';
}

function formatDate(date = 'now', needTime = false, needMoscow = false, customize = false, showWithoutUTC = false) {
    const _date = (date && date !== 'now') ? new Date(date) : new Date();
    const options = {
        month:  '2-digit',
        year:   'numeric',
        day:    '2-digit',
        hour12: false,
    };
    
    if (needTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
    }
    
    if (needMoscow) {
        options.timeZone = 'Europe/Moscow';
    }
    
    let dateTimeString = _date.toLocaleString('ru', options);
    
    if (/\d{4}-\d{2}-\d{2}/g.test(dateTimeString)) {
        // Дата может быть вида ГГГГ-ММ-ДД, тогда меняем на ДД.ММ.ГГГГ
        dateTimeString = dateTimeString.replace(/^(\d{4})-(\d{2})-(\d{2})[,\s]*(\d{0,2})(:)*(\d{0,2})$/, '$3.$2.$1 $4$5$6');
    }
    else {
        // Если дата не ГГГГ-ММ-ДД, она может быть ММ/ДД/ГГГГ, заменяем символы
        dateTimeString = dateTimeString.replace(/^(\d{2})\/(\d{2})\/(\d{4})[,\s]*(\d{0,2})(:)*(\d{0,2})$/, '$2.$1.$3 $4$5$6');
    }
    
    if (customize) {
        const [ cDate, cTime ] = dateTimeString.split(' ');
        const [ cDateDay, cDateMonth, cDateYear ] = cDate.split('.');
        let _dateTimeString = `« ${ cDateDay } » ${ MONTHS_WORDS[cDateMonth] } ${ cDateYear } г.`;
        
        if (needTime) {
            const [ cTimeHours, cTimeMinutes ] = cTime.split(':');
            _dateTimeString = `до ${ cTimeHours } ${ getHoursText(cTimeHours) } ${ cTimeMinutes } ${ getMinutesText(cTimeMinutes) } МСК\n${ _dateTimeString }`;
        }
        
        return _dateTimeString;
    }
    else {
        return (needTime && !showWithoutUTC) ? `${ dateTimeString } МСК` : dateTimeString.trim();
    }
}

export default {
    formatHumanDate:            formatHumanDate,
    formatHumanDateWithoutTime: formatHumanDateWithoutTime,
    dateLocalToTimestamp:       dateLocalToTimestamp,
    dateLocalToDateServer:      dateLocalToDateServer,
    dateLocalToISO:             dateLocalToISO,
    daysOrHoursToMilliseconds:  daysOrHoursToMilliseconds,
    getDateMMDDYYYYHHSS:        getDateMMDDYYYYHHSS,
    customFormatDate:           formatDate,
};
