<template>
  <ion-page>
    <ion-header class="ion-margin-bottom">
      <ion-toolbar>
        <h3 style="margin: 16px;">
          Панель администратора | Комментарии
        </h3>
      </ion-toolbar>
    </ion-header>

    <ion-content>
        <ion-row v-if="comments.length">
          <ion-col
              v-for="(comment, index) in comments"
              :key="index"
              size="12"
          >
            <ion-card>
                <ion-card-header
                    style="padding: 8px 16px; border-bottom: 1px solid #e0e0e0;"
                    class="ion-margin-bottom"
                >
                   <h4 style="padding: 0; margin: 0;">
                     <ion-text color="dark">
                       {{ !helpers.isEmptyObject(comment.object) ? comment.object.name : '' }}
                       <ion-chip
                           v-if="comment.status === statuses.CONFIRMED"
                           style="background-color: #2dd36f; color: white;"
                       >
                         Подтвержден
                       </ion-chip>

                       <ion-chip
                           v-if="comment.status === statuses.REJECTED"
                           style="background-color: #eb445a; color: white;"
                       >
                         Отклонен
                       </ion-chip>
                     </ion-text>
                   </h4>
                </ion-card-header>

                <ion-card-content>
                    <div
                        v-if="comment.comment"
                        class="ion-margin-bottom"
                    >
                        {{ comment.comment}}
                    </div>

                  <div
                      v-if="comment.createdDate"
                      class="ion-margin-bottom"
                  >
                    Дата отправки: {{ dateFormat.formatHumanDate(comment.createdDate) }}
                  </div>

                  <ion-button
                      v-if="comment.status !== statuses.CONFIRMED"
                      fill="solid"
                      style="margin-right: 16px;"
                      @click="showAlertConfirm(index)"
                  >
                    Подтвердить
                  </ion-button>

                  <ion-button
                      v-if="comment.status !== statuses.REJECTED"
                      color="danger"
                      @click="showAlertConfirm(index, true)"
                  >
                    Отклонить
                  </ion-button>
                </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <div
            v-else
            class="error"
        >
              <ion-icon
                  icon="chatbubbles"
                  style="font-size: 90px; margin: 0 auto 40px;"
              />
              <div class="error__title">
                  Комментарии не найдены
              </div>
              <div class="error__description">
                  Список комментариев пуст. Попробуйте получить их позже.
              </div>
            </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {ref, onBeforeMount} from 'vue';
import {alertController, IonCardContent, IonCardHeader} from "@ionic/vue";
import dateFormat from '../../../scripts/date_format';
import helpers from "@/scripts/helpers";
import axios from 'axios';
import {CONSTANTS} from "@/boot/constants";

const comments = ref([]);
const statuses = CONSTANTS.COMMENT_STATUSES;

async function showAlertConfirm(index, discard = false) {
    const action = discard ? 'отклонить' : 'подтвердить';
    const alert = await alertController
        .create({
          cssClass: 'add-point-confirm-close',
          header:   'Подтверждение действия',
          message:  `Вы уверены, что хотите ${action} комментарий для ${comments.value[index].object.name || ''}?`,
          buttons:  [
            {
              text:     'Отмена',
              role:     'cancel',
              cssClass: 'secondary',
            },
            {
              text:    'Подтвердить',
              handler: () => discard ? discardRouteComment(index) : confirmRouteComment(index),
            },
          ],
        });
    return alert.present();
}

async function confirmRouteComment(index) {
    const request = {
        id: comments.value[index].id,
    };
    try {
        const response = await axios.post('/api/comments/confirm', request);
        if (response.data.data.success) {
            comments.value[index].status = CONSTANTS.COMMENT_STATUSES.CONFIRMED;
            this.$success('Комментарий успешно подтвержден');
        }
    }
    catch(error) {
        console.error(error);
        console.error('ERROR CONFIRM COMMENT');
        this.$error('Не удалось подтвердить комментарий. Попробуйте позже или обратитесь к администратору');
    }
}

async function discardRouteComment(index) {
    const request = {
        id: comments.value[index].id,
    };
    try {
        const response = await axios.post('/api/comments/reject', request);
        if (response.data.data.success) {
            comments.value[index].status = CONSTANTS.COMMENT_STATUSES.REJECTED;
            this.$success('Комментарий успешно отклонен');
        }
    }
    catch(error) {
        console.error(error);
        console.error('ERROR REJECT COMMENTS');
        this.$error('Не удалось отклонить комментарий. Попробуйте позже или обратитесь к администратору');
    }
}

async function getRouteComments() {
    try {
        const response = await axios.post('/api/comments/show/all');
        comments.value = response.data.data;
    }
    catch(error) {
      console.error(error);
      console.error('ERROR GET COMMENTS');
      this.$error('Не удалось получить комментарии. Попробуйте позже или обратитесь к администратору');
    }
}

onBeforeMount(async () => {
    await getRouteComments();
});
</script>

<style scoped>
.error {
  display:        flex;
  flex-direction: column;
  align-items:    center;
  margin:         40px auto 20px;
}
.error__title {
    font-size:     32px;
    font-weight:   500;
    margin-bottom: 30px;
}
.error__description {
    font-size: 18px;
}
</style>