export const CONSTANTS = {
    ROUTE_STATUSES: {
        1: 'На верификации',
        2: 'Подтвержден',
        3: 'Отклонен',

        CHECK:     1,
        CONFIRMED: 2,
        REJECTED:  3,
    },

    COMMENT_STATUSES: {
        1: 'На верификации',
        2: 'Подтвержден',
        3: 'Отклонен',

        CHECK:     1,
        CONFIRMED: 2,
        REJECTED:  3,
    },
}